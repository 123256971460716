import * as React from "react"
import { useEffect, useRef, useState } from "react"
import {
  AppBar,
  Badge,
  Box,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  IconButton,
  Link,
  Popper,
  Toolbar,
  withStyles,
} from "@material-ui/core"

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { NavbarMenuStyles } from "../styles/NavbarMenu"
import ProductList from "./ProductList"
import { ArrowDropUp } from "@mui/icons-material"
import SearchItems from "./SearchItems"
import ConList from "./ConList"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import { useCart } from "../useCart/useCart"
import { navigate, withPrefix } from "gatsby"
import { useConsumableCart } from "../useCart/useConsumableCart"

type Props = {
  classes: {
    logo: string
    navLinks: string
    link: string
    popper: string
    popperBox: string
  }
}

const NavbarMenu = ({ classes }: Props) => {
  const redirect = () => navigate(`/cart`)

  const [open, setOpen] = useState(false)
  const [popperOpen, setPopperOpen] = useState(false)
  const [conOpen, setConOpen] = useState(false)
  const popperRef = useRef(null)
  const conRef = useRef(null)
  const { cart, handleAdd, handleRemove, handleQuantityChange } = useCart()
  const { cart: consumableCart } = useConsumableCart()
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopperOpen = e => {
    setAnchorEl(e.currentTarget)
    setPopperOpen(!popperOpen)
  }

  const conPopperOpen = e => {
    setAnchorEl(e.currentTarget)
    setConOpen(!conOpen)
  }
  return (
    <>
      <AppBar position="sticky" ref={popperRef}>
        <Container style={{ paddingLeft: 0, paddingRight: 0, minWidth: "90%" }}>
          <Toolbar style={{ height: "64px" }}>
            <img
              style={{ height: "100%", marginBottom: 0 }}
              src={withPrefix("images/bmc-equip-logo.gif")}
              alt={"BMC Logo"}
            />
            <Grid container alignItems="baseline">
              <Grid item>
                <div className={classes.navLinks}>
                  <Link href="/" className={classes.link} underline="none">
                    Home
                  </Link>
                  <Link
                    className={classes.link}
                    underline="none"
                    onClick={handlePopperOpen}
                    style={{ flexWrap: "nowrap" }}
                  >
                    <span>Machinery</span>
                    {!popperOpen ? <ArrowDropDownIcon /> : <ArrowDropUp />}
                  </Link>
                  <Link
                    className={classes.link}
                    underline="none"
                    onClick={conPopperOpen}
                    style={{ flexWrap: "nowrap" }}
                  >
                    <span>Consumables</span>
                    {!conOpen ? <ArrowDropDownIcon /> : <ArrowDropUp />}
                  </Link>
                  <Link
                    href="/contact"
                    className={classes.link}
                    underline="none"
                  >
                    Contact
                  </Link>
                  <Link
                    href="/catalog/cat?company=fmb"
                    className={classes.link}
                    underline="none"
                  >
                    Catalogues
                  </Link>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              justify={"flex-end"}
              alignItems={"center"}
              xs={12}
              md={12}
            >
              <Grid item xs={5} md={5}>
                <div style={{ width: "100%" }}>
                  <SearchItems />
                </div>
              </Grid>
              <Grid item xs={2} md={2}>
                <IconButton onClick={redirect}>
                  <Badge
                    badgeContent={cart.length + consumableCart.length}
                    color="secondary"
                    invisible={cart.length + consumableCart.length < 1}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <Popper
        open={popperOpen}
        anchorEl={popperRef.current}
        className={classes.popper}
        transition={true}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (popperOpen) {
                setPopperOpen(false)
              }
            }}
          >
            <Grow {...TransitionProps} timeout={200}>
              <Box sx={{ border: 1, p: 1 }} className={classes.popperBox}>
                <ProductList />
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>

      <Popper
        open={conOpen}
        anchorEl={popperRef.current}
        className={classes.popper}
        transition={true}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (conOpen) {
                setConOpen(false)
              }
            }}
          >
            <Grow {...TransitionProps} timeout={200}>
              <Box sx={{ border: 1, p: 1 }} className={classes.popperBox}>
                <ConList />
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

// @ts-ignore
export default withStyles(NavbarMenuStyles)(NavbarMenu)
