import {flatMap, forEach, map, reduce} from "lodash"
import * as React from "react"
import {ProductData} from "./partials"
import {useState} from "react"
import {Autocomplete} from "@material-ui/lab"
import {Box, Grid, TextField} from "@material-ui/core"
import Image from "./Image"
import {navigate} from "gatsby"
import {ConsumableData} from "./ConsumableData"

const data = () => {
    const models = ProductData()
    return models;
}

const consumableData = () => {
    const models = ConsumableData()
    const mappedModels = models.map(model => ({
        ...model,
        metadata: {
            ...model.metadata,
            machine_type: {
                value: "consumable"
            },
            imageurl: {
                url: model.metadata.image.url,
            },
        },
        manufacturer: "Consumables",
        type: "consumable",
    }))

    return mappedModels
}

const SearchItems = () => {
    const redirect = target => navigate(`/products/${target.toLowerCase()}`)
    const consumableRedirect = target =>
        navigate(`/consumable/${target.toLowerCase()}`)
    const models = data()
    const consumables = consumableData()
    return (
        <Autocomplete
            style={{
                minWidth: "200px",
            }}
            options={[...models, ...consumables]}
            autoHighlight
            getOptionLabel={option => option.title + " " + option.metadata.manufacturer}
            groupBy={option => option.metadata.manufacturer}
            renderOption={(option, props) => {
                return (
                    <Box
                        {...props}
                        style={{width: "100%", padding: 0}}
                        onClick={() =>
                            option.type ? consumableRedirect(option.slug) : redirect(option.slug)
                        }
                    >
                        <Grid container style={{height: "100px"}}>
                            <Grid item style={{height: "100px"}} xs={6}>
                                <Image alt={option.title} filename={option.metadata.imageurl.url}/>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                direction={"column"}
                                justify={"space-evenly"}
                            >
                                {!option.metadata.machine_type.value &&
                                    <Grid item>{option.metadata.manufacturer}</Grid>}
                                <Grid item>{option.title}</Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Search"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}

export default SearchItems
