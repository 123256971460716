import * as React from "react"
import {graphql, navigate, useStaticQuery} from "gatsby"
import {filter, find, first, groupBy, isObject, last} from "lodash"
import {
    Card,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
    withStyles,
} from "@material-ui/core"
import {
    BreadLinkStyles,
    BreadTypographyStyles,
    PageHeaderStyles,
    ProductCardStyles,
} from "../styles/PartialStyles"
import Image from "./Image"
import {Consumable, Model} from "../types/Types"
import PhoneIcon from "@material-ui/icons/Phone"
import FaxIcon from "@mui/icons-material/Fax"
import EmailIcon from "@material-ui/icons/Email"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import {ConsumableData} from "./ConsumableData"

type ContactHeaderProps = {
    classes: {
        pageHeader: string
        divider: string
    }
    id: string
    header: string
    description?: string
}

type ProductCardProps = {
    classes: {
        card: string
        image: string
        imageDiv: string
    }
    model: Model
    category: {
        category: string
        image: string
    }
}

type ConsumableCardProps = {
    classes: {
        card: string
        image: string
        imageDiv: string
    }
    consumable: Consumable
}
export const PageHeader = withStyles(PageHeaderStyles)(
    ({classes, id, header, description = null}: ContactHeaderProps) => (
        <Grid
            id={id}
            container
            justify="center"
            alignItems="center"
            className={classes.pageHeader}
        >
            <Grid item xs={12} md={12}>
                <Container style={{paddingLeft: 0, paddingRight: 0}}>
                    <Typography variant="h3">{header}</Typography>
                </Container>
            </Grid>
            {description != null && (
                <Grid item xs={12} md={12}>
                    <Container style={{paddingLeft: 0, paddingRight: 0}}>
                        <Typography variant="h5">{description}</Typography>
                    </Container>
                </Grid>
            )}
        </Grid>
    )
)

export const ProductData = () => {
    const data = useStaticQuery(graphql`
        {
            allCosmicjsMachines {
                edges {
                    node {
                        id
                        metadata {
                            bullet_points
                            catalogurl
                            description
                            catalogue {
                                url
                            }
                            imageurl {
                                url
                            }
                            machine_type {
                                value
                            }
                            manufacturer
                            name
                            options
                            sitelink
                            specs {
                                url
                            }
                            youtube
                        }
                        thumbnail
                        slug
                        title
                    }
                }
            }
        }
    `)
    return data.allCosmicjsMachines.edges.flatMap(value => value.node);
}

export const getCategoryList = () => {
    const productData = ProductData()

    return groupBy(productData, value => value.metadata.machine_type.value);
}

export const getModelList = (type: String) => {
    const productData = ProductData()

    return first(
        productData.filter(
            value => value.node.category.toLowerCase() == type.toLowerCase()
        )
    ).node
}

export const getConsumableByPath = (path: String) => {
    const productData = ConsumableData()
    return first(
        filter(productData[0].node.consumables, value => value.id == path)
    )
}

export const BreadLink = withStyles(BreadLinkStyles)(Link)

export const Capitalize = str => {
    return str != null && str.charAt(0).toUpperCase() + str.slice(1)
}

export const ProductCard = withStyles(ProductCardStyles)(
    ({model, classes}: ProductCardProps) => {
        const redirect = target => navigate(`/products/${target.toLowerCase()}`)

        return (
            <Grid item>
                <Card
                    className={classes.card}
                    sx={{minWidth: "100%"}}
                    onClick={() => redirect(model.slug)}
                >
                    <CardContent style={{height: "100%", padding: 0}}>
                        <Grid
                            container
                            style={{width: "300px", height: "100%"}}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify={"center"}
                                style={{height: "100%", backgroundColor: "#F5F1ED"}}
                                xs
                            >
                                <Grid item>
                                    <div
                                        className={classes.imageDiv}
                                        style={{maxHeight: "100%", height: "300px"}}
                                    >
                                        <Image
                                            className={classes.image}
                                            alt={model.slug}
                                            filename={model.metadata.imageurl.url}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item style={{paddingLeft: "10px"}}>
                                <Typography variant={"body1"}>{model.metadata.manufacturer}</Typography>
                                <Typography variant={"h4"} style={{whiteSpace: "nowrap"}}>
                                    {model.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
)

export const ConsumableCard = withStyles(ProductCardStyles)(
    ({consumable, classes}: ConsumableCardProps) => {
        const redirect = target => navigate(`/consumable/${target.toLowerCase()}`)

        return (
            <Grid item>
                <Card
                    className={classes.card}
                    sx={{minWidth: "100%"}}
                    onClick={() => redirect(consumable.slug)}
                >
                    <CardContent style={{height: "100%", padding: 0}}>
                        <Grid
                            container
                            style={{width: "300px", height: "100%"}}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify={"center"}
                                style={{height: "100%", backgroundColor: "#F5F1ED"}}
                                xs
                            >
                                <Grid item>
                                    <div
                                        className={classes.imageDiv}
                                        style={{maxHeight: "100%", height: "300px"}}
                                    >
                                        <Image
                                            className={classes.image}
                                            alt={consumable.slug}
                                            filename={consumable.metadata.image.url}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item style={{paddingLeft: "10px"}}>
                                <Typography variant={"h6"} style={{whiteSpace: "nowrap"}}>
                                    {consumable.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
)

export const CategoryCard = withStyles(ProductCardStyles)(
    ({category, classes}: ProductCardProps) => {
        const redirect = target => navigate(`/categories/${target.toLowerCase()}`)

        console.log(category);
        return (
            <Grid item>
                <Card
                    className={classes.card}
                    sx={{minWidth: "100%"}}
                    onClick={() => redirect(category.category)}
                >
                    <CardContent style={{height: "100%", padding: 0}}>
                        <Grid
                            container
                            style={{width: "300px", height: "100%"}}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify={"center"}
                                style={{height: "100%", backgroundColor: "#F5F1ED"}}
                                xs
                            >
                                <Grid item>
                                    <div
                                        className={classes.imageDiv}
                                        style={{maxHeight: "100%", height: "300px"}}
                                    >
                                        {category.image && (
                                            <Image
                                                className={classes.image}
                                                alt={category.image}
                                                filename={category.image}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item style={{paddingLeft: "10px"}}>
                                <Typography variant={"h4"}>{category.category}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
)

export const ContactDetails = () => (
    <>
        <Grid item container xs={12} md={12} spacing={2} alignItems={"flex-start"}>
            <Grid item>
                <PhoneIcon/>
            </Grid>
            <Grid item>
                <Typography
                    variant="body1"
                    component={Link}
                    href={"tel:00%20353%2046%20955%208121"}
                    target={"_blank"}
                >
                    00 353 46 955 8121
                </Typography>
            </Grid>
        </Grid>
        <Grid item container xs={12} md={12} spacing={2} alignItems={"flex-start"}>
            <Grid item>
                <FaxIcon/>
            </Grid>
            <Grid item>
                <Typography variant="body1">00 353 46 955 8122</Typography>
            </Grid>
        </Grid>
        <Grid item container xs={12} md={12} spacing={2} alignItems={"flex-start"}>
            <Grid item>
                <EmailIcon/>
            </Grid>
            <Grid item>
                <Typography
                    variant={"body1"}
                    component={Link}
                    href={"mailto:info@bmcequip.com"}
                    target={"_blank"}
                >
                    info@bmcequip.com
                </Typography>
            </Grid>
        </Grid>
        <Grid item container xs={12} md={12} spacing={2} alignItems={"flex-start"}>
            <Grid item>
                <LocationOnIcon/>
            </Grid>
            <Grid
                item
                style={{
                    width: "80%",
                    whiteSpace: "break-spaces",
                }}
            >
                <Typography variant={"body1"}>
                    {"Unit 4A Summerhill Ent. Ctr.\n" +
                        "Summerhill\n" +
                        "Co. Meath\n" +
                        "Ireland"}
                </Typography>
            </Grid>
        </Grid>
    </>
)

export const BreadTypography = withStyles(BreadTypographyStyles)(Typography)
