import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"
import {
  AppBar,
  Container,
  CssBaseline,
  Grid,
  MuiThemeProvider,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core"
import DrawerMenu from "./DrawerMenu"
import NavbarMenu from "./NavbarMenu"
import { GlobalTheme } from "../styles/theme/GlobalTheme"
import { LayoutStyles } from "../styles/PartialStyles"
import FeaturedBanner from "./FeaturedBanner"
import FooterBar from "./FooterBar"

type Props = {
  children: React.ReactNode
  HeaderComponent?: React.ReactElement
  BreadComponent?: React.ReactElement
  hasFeatured?: boolean
  classes: {
    container: string
  }
}
const Layout = ({
  children,
  HeaderComponent = null,
  BreadComponent = null,
  hasFeatured = false,
  classes,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MuiThemeProvider theme={GlobalTheme}>
      <CssBaseline>
        <>
          {isMobile ? <DrawerMenu /> : <NavbarMenu />}
          {HeaderComponent}
          <Grid container className={classes.container} alignItems={"center"}>
            {BreadComponent && (
              <Grid item md={12} xs={12} style={{ padding: "5px" }}>
                <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {BreadComponent}
                </Container>
              </Grid>
            )}
          </Grid>
          <div
            style={{
              margin: `0 auto`,
              marginTop: "25px",
              maxWidth: isMobile ? "80%" : "70%",
            }}
          >
            <main>{children}</main>
          </div>
          {hasFeatured && (
            <div id={"featuredContainer"}>
              <FeaturedBanner />
            </div>
          )}
          <FooterBar />
        </>
      </CssBaseline>
    </MuiThemeProvider>
  )
}

export default withStyles(LayoutStyles)(Layout)
