import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { find, isObject } from "lodash"
import * as React from "react"

type ImageProps = {
  alt: string
  filename: string
}

const Image = ({ alt, filename, ...props }: ImageProps) => {
  const data = useStaticQuery(graphql`
    {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100, base64Width: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = find(data.images.edges, value => filename.includes(value.node.name))
  if (isObject(file)) {
    // @ts-ignore
    return (
      <Img
        {...props}
        style={{ maxHeight: "100%", height: "100%" }}
        imgStyle={{ objectFit: "contain" }}
        alt={alt}
        fluid={file.node.childImageSharp.fluid}
      />
    )
  }
  return null
}

export default Image
