import { graphql, useStaticQuery } from "gatsby"

export const ConsumableData = () => {
  const data = useStaticQuery(graphql`
      {
          allCosmicjsConsumables {
              edges {
                  node {
                      id
                      metadata {
                          description
                          image {
                              url
                          }
                          options {
                              desc
                              values
                          }
                          specs {
                              url
                          }
                      }
                      slug
                      thumbnail
                      title
                      type
                  }
              }
          }
      }
  `)

  return data.allCosmicjsConsumables.edges.flatMap(consumable => consumable.node)
}
