import * as React from "react"
import {
  Badge,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  withStyles,
} from "@material-ui/core"
import { useState } from "react"
import MenuIcon from "@material-ui/icons/Menu"
import { DrawerStyles } from "../styles/PartialStyles"
import SearchItems from "./SearchItems"
import { useCart } from "../useCart/useCart"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import { navigate, withPrefix } from "gatsby"

type Props = {
  classes: {
    button: string
    drawer: string
  }
}
const DrawerMenu = ({ classes }: Props) => {
  const redirect = () => navigate(`/cart`)
  const { cart, handleAdd, handleRemove, handleQuantityChange } = useCart()
  const [open, setOpen] = useState(false)
  return (
    <>
      <Drawer open={open} classes={{ paper: classes.drawer }}>
        <IconButton onClick={() => setOpen(!open)} className={classes.button}>
          <MenuIcon />
        </IconButton>
        <List>
          <ListItem>
            <ListItemText onClick={() => setOpen(false)}>
              <Link href="/">Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link href="/categories">Machinery</Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link href="/consumables">Consumables</Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link href="/contact">Contact Us</Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Link href="/catalog/cat?company=fmb">Catalogues</Link>
            </ListItemText>
          </ListItem>
        </List>
        <div style={{ padding: "5%" }}>
          <SearchItems />
        </div>
      </Drawer>
      <Grid container style={{ backgroundColor: "#fefefe" }}>
        <Grid item xs={2}>
          <IconButton onClick={() => setOpen(!open)} className={classes.button}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <img
            alt={"BMC logo"}
            style={{ height: "100%", marginBottom: 0 }}
            src={withPrefix("images/bmc-equip-logo.gif")}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={redirect}>
            <Badge
              badgeContent={cart.length}
              color="secondary"
              invisible={cart.length < 1}
            >
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(DrawerStyles)(DrawerMenu)
