import * as React from "react"
import {
    Divider,
    Grid,
    Link,
    List,
    ListItemText,
    ListSubheader,
    withStyles,
} from "@material-ui/core"
import {ProductListStyles} from "../styles/ProductList"
import {ProductData} from "./partials"
import {groupBy} from "lodash";
import {Dictionary} from "@reduxjs/toolkit";
import {Model} from "../types/Types";
import {object} from "prop-types";

const testList = [
    {
        name: "Ironworkers",
        models: [
            {
                name: "HKM40",
            },
            {
                name: "HKM40",
            },
            {
                name: "HKM40",
            },
        ],
    },
    {
        name: "Pressbrakes Guillotine",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Saws",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Belt Sanders",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Ring Rollers",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Plasma / Laser",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Belt Sanders",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Ring Rollers",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Plasma / Laser",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Belt Sanders",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Ring Rollers",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
    {
        name: "Plasma / Laser",
        models: [
            {
                name: "B135",
            },
            {
                name: "B175",
            },
            {
                name: "B220",
            },
        ],
    },
]

type Props = {
    classes: {
        listSubheader: string
        listItemContent: string
        container: string
        listSubheaderContent: string
    }
}

const ProductList = ({classes}: Props) => {
    const products = ProductData() as Model[];
    const productByCategory = groupBy(products, product => product.metadata.machine_type.value);

    const productByCategoryArray = Object.entries(productByCategory);

    console.log(productByCategoryArray);
    return (
        <Grid
            container
            className={classes.container}
            spacing={1}
            justify={"space-between"}
        >
            {productByCategoryArray.map((item) => {
                return (
                    <Grid item style={{minWidth: "300px"}}>
                        <List>
                            <ListSubheader className={classes.listSubheader}>
                                <Link
                                    href={`/categories/${item[0].toLowerCase()}`}
                                    className={classes.listSubheaderContent}
                                >
                                    {item[0]}
                                </Link>
                            </ListSubheader>
                            <div
                                style={{
                                    height: "175px",
                                    paddingRight: "12px",
                                    columnCount:
                                        item[0] == "Saws"
                                            ? 5
                                            : item[0] == "Steelworkers" ||
                                            item[0] == "Notchers" || item[0] == "Other Machines"
                                                ? 2
                                                : 1,
                                }}
                            >
                                {item[1].map(model => (
                                    <ListItemText>
                                        <Link
                                            href={`/products/${model.slug}`}
                                            className={classes.listItemContent}
                                            underline="none"
                                        >
                                            {model.title}
                                        </Link>
                                    </ListItemText>
                                ))}
                            </div>
                        </List>
                    </Grid>
                );
            })}
        </Grid>
    )
}

export default withStyles(ProductListStyles)(ProductList)
