import { Grid, Theme, useTheme } from "@material-ui/core"
import * as React from "react"
import { Global } from "@emotion/react"
import { GlobalTheme } from "./theme/GlobalTheme"

export const TextStyles = {
  centered: {
    textAlign: "center",
  },
  justify: {
    textAlign: "justify",
  },
}

export const BreadLinkStyles = (theme: Theme = useTheme()) => ({
  root: {
    color: theme.palette.background.default,
  },
})

export const PageHeaderStyles = (theme: Theme = useTheme()) => ({
  pageHeader: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.primary.main,
    fontStyle: "italic",
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    height: theme.typography.pxToRem(5),
    backgroundColor: theme.palette.primary.main,
  },
})

export const ContactBannerStyles = (theme: Theme = useTheme()) => ({
  container: {},
  divider: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    height: theme.typography.pxToRem(5),
  },
  phone: {
    cursor: "pointer",
    padding: theme.spacing(1),
    textAlign: "center",
    minHeight: theme.spacing(8),
  },
  email: {
    cursor: "pointer",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  fax: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  emailForm: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
})

export const ProductCardStyles = (theme: Theme = useTheme()) => ({
  image: {
    width: theme.typography.pxToRem(300),
    height: "auto",
    // backgroundColor: theme.palette.background.default,
  },
  imageDiv: {
    background: `linear-gradient(to top, #d7d7d7 50%, ${theme.palette.background.default} 50%)`,
    backgroundSize: "100% 200%",
    backgroundPosition: "top",
    transition: "all 0.5s ease",
    "&:hover": {
      backgroundPosition: "bottom",
    },
  },
  card: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.secondary.main,
    cursor: "pointer",
    marginBottom: theme.typography.pxToRem(20),
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: 0,
    "& img": {
      transition: "transform .5s",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    "&:hover": {
      backgroundPosition: "left bottom",
    },
  },
})

export const LayoutStyles = () => {
  const theme: Theme = GlobalTheme
  return {
    container: {
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: theme.palette.secondary.main,
    },
  }
}

export const BreadTypographyStyles = () => {
  const theme: Theme = GlobalTheme
  return {
    root: {
      color: theme.palette.info.main,
      fontWeight: "bold",
    },
  }
}

export const DrawerStyles = () => {
  const theme: Theme = GlobalTheme
  return {
    button: {
      color: theme.palette.secondary.main,
    },
    drawer: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main,
      width: "50%",
    },
  }
}

export const FeaturedBannerStyles = () => {
  const theme: Theme = GlobalTheme
  return {
    banner: {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.primary.main,
      marginTop: theme.spacing(4),
    },
    title: {
      fontStyle: "italic",
    },
    modelDetails: {
      color: theme.palette.info.main,
      backgroundColor: theme.palette.secondary.main,
    },
    imageDiv: {
      background: `linear-gradient(to top, #d7d7d7 50%, ${theme.palette.background.default} 50%)`,
      backgroundSize: "100% 200%",
      backgroundPosition: "top",
      transition: "all 0.5s ease",
      "& img": {
        transition: "transform .5s",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
      "&:hover": {
        backgroundPosition: "bottom",
      },
      height: "200px",
    },
    gridItem: {
      cursor: "pointer",
    },
  }
}

export const FooterBarStyles = (theme: Theme = useTheme()) => ({
  root: {
    borderBottom: "none",
    backgroundColor: theme.palette.primary.main,
    "& h4": {
      color: theme.palette.info.main,
    },
    "& h6": {
      color: theme.palette.info.dark,
    },
    "& a": {
      color: theme.palette.info.dark,
    },
    color: theme.palette.info.dark,
  },
})

export const IndexCubeStyles = (theme: Theme = useTheme()) => ({
  container: {
    position: "relative",
    textAlign: "center",
  },
  topBar: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    background: "rgba(24,24,24,0.5)",
    height: "10%",
  },
  title: {
    fontStyle: "italic",
    color: theme.palette.info.main,
    paddingLeft: theme.spacing(3),
  },
})
