import * as React from "react"
import { createMuiTheme } from "@material-ui/core"

const raleway = {
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url("../fonts/Roverto-Black.woff") format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
}

const colors = {
  palette: {
    background: {
      default: "#F5F1ED",
      paper: "#F5F1ED",
    },
    primary: {
      main: "#252323",
    },
    divider: "#333333",
    secondary: {
      main: "#063f6b",
    },
    typography: {
      fontFamily: "Raleway",
      primary: "#cc0000",
      secondary: "white",
    },
    text: {
      primary: "#111111",
      secondary: "#333333",
    },
    info: {
      main: "#F5F1ED",
      light: "#b1c5e7",
      dark: "#6d6d6d",
    },
    warning: {
      main: "#EE332F",
    },
    action: {
      active: "#cc0000",
      disabled: "#3e403e",
    },
  },
}

const overrides = {
  overrides: {
    MuiContainer: {
      root: {
        width: "80%",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [raleway],
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: "#333333",
        color: "#FCFAF9",
        borderRadius: 0,
      },
    },
    MuiListItem: {
      button: {
        color: colors.palette.action.active,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fefefe",
        color: colors.palette.primary.main,
      },
      root: {
        borderBottom: `2px solid ${colors.palette.secondary.main}`,
        zIndex: 100000,
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: colors.palette.info.main,
      },
    },
    MuiAutocomplete: {
      groupUl: {
        margin: 0,
      },
    },
    MuiAccordion: {
      root: {
        background: colors.palette.primary.main,
        color: colors.palette.info.main,
      },
    },
  },
}
// @ts-ignore
export const GlobalTheme = createMuiTheme({
  ...colors,
  ...overrides,
})
