import * as React from "react"
import {
  Divider,
  Grid,
  Link,
  List,
  ListItemText,
  ListSubheader,
  withStyles,
} from "@material-ui/core"
import { ProductListStyles } from "../styles/ProductList"
import { ProductData } from "./partials"
import { ConsumableData } from "./ConsumableData"
import { Consumable } from "../types/Types"

type Props = {
  classes: {
    listSubheader: string
    listItemContent: string
    container: string
    listSubheaderContent: string
  }
}

const ConList = ({ classes }: Props) => {
  const consumables = ConsumableData()

  return (
    <Grid container className={classes.container}>
      {consumables.map((item: Consumable) => (
        <Grid item xs={3} md={3}>
          <List>
            <ListSubheader className={classes.listSubheader}>
              <Link
                href={`/consumable/${item.slug.toLowerCase()}`}
                className={classes.listSubheaderContent}
              >
                {item.title}
              </Link>
            </ListSubheader>
          </List>
        </Grid>
      ))}
    </Grid>
  )
}

export default withStyles(ProductListStyles)(ConList)
