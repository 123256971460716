import {
  AppBar,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core"
import { ContactDetails } from "./partials"
import SearchItems from "./SearchItems"
import * as React from "react"
import { FooterBarStyles } from "../styles/PartialStyles"
import { StaticImage } from "gatsby-plugin-image"
import { withPrefix } from "gatsby"

type Props = {
  classes: {
    root: string
  }
}
const FooterBar = ({ classes }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <AppBar
      position="static"
      color="primary"
      className={classes.root}
      style={{
        marginTop: "calc(5% + 60px)",
        bottom: 0,
        height: "100%",
        padding: "8px",
      }}
    >
      <Container
        maxWidth="md"
        style={{
          margin: `0 auto`,
          marginTop: "10px",
          maxWidth: isMobile ? "80%" : "70%",
        }}
      >
        <Grid container alignItems="baseline" justify="space-evenly">
          <Grid
            item
            container
            justify={"center"}
            xs={12}
            md={3}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant={"h6"}
                style={{ paddingBottom: "8px", textAlign: "center" }}
              >
                Quick Links
              </Typography>
            </Grid>
            <Grid item>
              <List style={{ paddingTop: 0 }}>
                <ListItem>
                  <ListItemText>
                    <Link href="/">Home</Link>
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Link href="/categories">Machinery</Link>
                  </ListItemText>
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText>
                    <Link href="/404">Consumables</Link>
                  </ListItemText>
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText>
                    <Link href="/contact">Contact Us</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={3}
            style={{ marginBottom: "20px" }}
            justify="center"
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant={"h6"}
                style={{ paddingBottom: "8px", textAlign: "center" }}
              >
                Contact Us
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <ContactDetails />
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify={"center"}
            xs={12}
            md={3}
            style={{ marginBottom: "20px" }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                variant={"h6"}
                style={{ paddingBottom: "8px", textAlign: "center" }}
              >
                Search Our Products
              </Typography>
            </Grid>
            <Grid item container xs={12} md={12} spacing={5}>
              <Grid item xs={12} md={12}>
                <SearchItems />
              </Grid>
              <Grid item xs={12} md={12}>
                <img
                  style={{ height: "100%", marginBottom: 0 }}
                  src={withPrefix("images/bmc-equip-logo.gif")}
                  alt={"BMC Logo"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

export default withStyles(FooterBarStyles)(FooterBar)
