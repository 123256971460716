import { useTheme } from "@material-ui/core"

export const ProductListStyles = (theme = useTheme()) => ({
  container: {
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxHeight: "80vh",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "auto",
  },
  listSubheader: {
    paddingLeft: 0,
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.secondary.main,
    position: "relative",
  },
  listSubheaderContent: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  listItemContent: {
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
    },
  },
})
