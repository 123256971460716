import { useTheme } from "@material-ui/core"

export const NavbarMenuStyles = (theme = useTheme()) => ({
  navLinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "20px",
    marginLeft: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  popper: {
    width: "80%",
    maxWidth: "1620px",
    zIndex: 10,
    left: "10%",
  },
  popperBox: {
    borderBottom: `5px double ${theme.palette.secondary.main}`,
    padding: "20px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    backgroundColor: theme.palette.background.default,
  },
})
