import * as React from "react"
import {chunk, flatMap, groupBy, shuffle, take} from "lodash"
import { ProductData } from "./partials"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core"
import { FeaturedBannerStyles } from "../styles/PartialStyles"
import Carousel from "react-material-ui-carousel"
import Image from "./Image"
import { Model } from "../types/Types"
import { DefaultSettingsT, SettingsT } from "./Settings"
import { useState } from "react"
import "../styles/Settings.css"
import { navigate } from "gatsby"

type Props = {
  classes: {
    banner: string
    title: string
    modelDetails: string
  }
}

type BannerProps = {
  classes: {
    imageDiv: string
    modelDetails: string
    gridItem: string
  }

  Items: Model[]
  contentPosition: "left" | "right" | "middle"
  length?: number
}

const data = () => {
    const models = ProductData();
    return models;
}

const FeaturedBanner = ({ classes }: Props) => {
  const models = take(chunk(shuffle(data()), 3), 3)
  const [settings] = useState<SettingsT>(DefaultSettingsT)

  return (
    <Box id={"featuredContainer"} className={classes.banner}>
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Typography className={classes.title} variant="h3">
          Featured Products
        </Typography>
        <Carousel {...settings}>
          {models.map(modelSub => (
            <Banner Items={modelSub} contentPosition={"left"} length={3} />
          ))}
        </Carousel>
      </Container>
    </Box>
  )
}

const Banner = withStyles(FeaturedBannerStyles)(
  ({ classes, Items, contentPosition, length }: BannerProps) => {
    const position = contentPosition ? contentPosition : "left"
    const totalItems = Items.length
    const mediaLength = totalItems

    const redirect = target => navigate(`/products/${target.toLowerCase()}`)

    let items = []

    for (let i = 0; i < mediaLength; i++) {
      const model = Items[i]

      const media = (
        <Grid
          item
          xs={12}
          md={4}
          key={model.slug}
          className={classes.gridItem}
          onClick={() => redirect(model.slug)}
        >
          <div className={classes.imageDiv}>
            <Image alt={model.title} filename={model.metadata.imageurl.url} />
          </div>
          <div className={classes.modelDetails}>
            <Container>
              <Typography variant={"body1"}>{model.metadata.manufacturer}</Typography>
              <Typography variant={"h5"} style={{ whiteSpace: "nowrap" }}>
                {model.title}
              </Typography>
            </Container>
          </div>
        </Grid>
      )

      items.push(media)
    }

    return (
      <Card raised className="Banner">
        <Grid
          container
          spacing={1}
          className="BannerGrid"
          style={{ backgroundColor: "#252323" }}
        >
          {items}
        </Grid>
      </Card>
    )
  }
)

export default withStyles(FeaturedBannerStyles)(FeaturedBanner)
